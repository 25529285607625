#orange-sticky
  outline: none
  box-sizing: border-box
  z-index: 9999999999999999999999999999999999999
  .sticky
    position: fixed
    width: 100px
    height: 100px
    right: 0
    background: $orange
    justify-content: center
    cursor: pointer
    align-items: center
    display: inline-block
    img
      position: relative
      left: 50%
      top: 20px
      transform: translate(-50%)
      max-width: 50px
    p
      color: white
      text-align: center
      padding-top: 5px
  .termin
    top: 150px
  .mail
    top: 275px
  .telefon
    top: 400px

    .popuptext
      display: none
      width: 250px
      background-color: $gray
      color: white
      font-size: 1.4em!important
      text-align: center
      padding: 15px 0
      position: absolute
      z-index: 1
      top: 20%
      left: -200%
      margin-left: -80px
      z-index: 999999999999999999999999999999999999999999999999999
      &::after
        content: ""
        position: absolute
        z-index: 999999999999999999999999999999999999999999999999999
        top: 27%
        left: 100%
        border-width: 15px
        border-style: solid
        border-color: transparent transparent transparent $gray


      @keyframes fadeIn
        0%
          opacity: 0
        100%
          opacity: 1








@media (max-width: 690px)
  section#orange-sticky
    position: fixed
    bottom: 0
    width: 100%
    height: 55px
    display: flex
    background: $orange
    .flexbox
      width: 100%
      display: flex
      flex-wrap: wrap
      flex-direction: row
      position: relative
      justify-content: space-around
      .orange-button
        position: relative
        bottom: 0
        width: 32%
        height: auto
        display: block
        align-items: center
        justify-content: space-around
        a
          width: 100%
          height: 100%
          display: block

        .sticky
          position: relative
          width: 100%
          height: auto
          right: auto
          background: none
          justify-content: center
          cursor: pointer
          align-items: center
          display: block
          text-align: center
          img
            position: static
            margin-top: 5px
            max-width: 25px
            margin-left: 25px


          p
            color: white
            text-align: center
            padding-top: 0
            margin: 0 auto
            text-decoration: none
            font-size: 0.8em

        .termin
          width: 100%
          text-align: center
          top: 0
          left: 0
          right: auto
        .mail
          width: 100%
          top: 0
          left: 0
          right: auto
          text-align: center
        .telefon
          width: 100%
          top: 0
          left: 0
          right: auto
          text-align: center
