#infoOne
    position: relative

    .image-1
        height: 750px
        width: 100%
        position: relative
        background: url("../img/info/header.jpg") center top 38%
        background-size: cover
    .info
      display: flex
      flex-wrap: wrap
      .col-1
        flex: 1 0 0
        min-width: 300px
        margin: 10px
        img 
          width: 100%
          max-width: 350px
          margin-top: 20px


@media (max-width: 1400px)
  section#header

    .image-1
      height: 550px



@media (max-width: 690px)
  section#header

    .image-1
      height: 300px
      h1
        padding-top: 00px