/* Slider */
.slick-slider
  position: relative
  display: block
  box-sizing: border-box
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  -webkit-t-ms-touch-action: pan-y
  touch-action: pan-y
  -ms-touch-action: pan-y
  touch-action: pan-y
  -webkit-tap-highlight-color: transparent

.slick-list

  position: relative

  display: block
  overflow: hidden

  margin: 0
  padding: 0
  height: 100%

.slick-list:focus

  outline: none

.slick-list.dragging

  cursor: pointer
  cursor: hand


.slick-slider .slick-track,
.slick-slider .slick-list

  -webkit-transform: translate3d(0, 0, 0)
  -moz-transform: translate3d(0, 0, 0)
  -ms-transform: translate3d(0, 0, 0)
  -o-transform: translate3d(0, 0, 0)
  transform: translate3d(0, 0, 0)


.slick-track

  position: relative
  top: 0
  left: 0
  height: 100%

  display: block

.slick-track:before,
.slick-track:after

  display: table

  content: ""

.slick-track:after

  clear: both

.slick-loading .slick-track

  visibility: hidden


.slick-slide

  display: none
  float: left

  height: 100%
  min-height: 1px

[dir='rtl'] .slick-slide

  float: right


.slick-slide.slick-loading
  img

    display: none

.slick-slide.dragging
  img

    pointer-events: none

.slick-initialized .slick-slide

  display: block

.slick-loading .slick-slide

  visibility: hidden

.slick-vertical .slick-slide

  display: block

  height: auto

  border: 1px solid transparent

.slick-arrow.slick-hidden
  display: none


.slick-prev:before,
.slick-next:before

  font-family: 'slick'
  font-size: 35px
  line-height: 1

  opacity: .75
  color: $orange
  z-index: 99

  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.slick-prev,
.slick-next
  font-size: 0
  line-height: 0
  position: absolute
  top: 50%
  display: block
  width: 50px!important
  height: 50px!important
  z-index: 9999999999999999999
  padding: 0
  -webkit-transform: translate(0, -50%)
  -ms-transform: translate(0, -50%)
  transform: translate(0, -50%)
