footer
  position: relative
  margin-top: 100px

  .footer-ul
    position: relative
    justify-content: center
    max-width: 1400px
    font-family: "Fira Sans", sans-serif
    margin: 0 auto
    text-align: center
    a
      margin: 0px 50px 0px 0px
      text-align: center
      font-family: "Fira Sans", sans-serif
      font-weight: 300
      font-size: 1em
      color: $gray
      text-transform: uppercase
      text-decoration: none
      padding-top: 10px
      &:hover
        border-bottom: 3px solid $orange
