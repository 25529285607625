@import url('https://fonts.googleapis.com/css?family=Fira+Sans:200,300,500,600,700')

*
	font-size: 14px
	line-height: 1.8
	@media (max-width: 768px)
		font-size: 12px

h1
	font-family: "Fira Sans", sans-serif
	font-weight: 700
	font-size: 6.5em
	color: $orange
	line-height: 1.2
	position: absolute
	top: 30%
	left: 20%
	transition: -20%
// h1
// 	font-family: 'Fira Sans', sans-serif
// 	font-weight: 700
// 	font-size: 7em
// 	color: $orange
// 	line-height: 0.15
// 	padding: 450px 0px 0px 250px

span
	font-family: 'Fira Sans', sans-serif
	font-weight: 300
	font-size: 1em
	color: white

h2
	font-family: 'Fira Sans', sans-serif
	font-weight: 600
	font-size: 1.6em
	color: $gray
	line-height: 1.2

h3
	font-family: 'Fira Sans', sans-serif
	font-weight: 200
	font-size: 2em
	color: $gray
	line-height: 1.2


p
	font-family: 'Fira Sans', sans-serif
	font-weight: 300
	font-size: 1em
	color: $gray

a
	font-family: 'Fira Sans', sans-serif
	font-weight: 300
	font-size: 1em
	color: $gray

strong
	font-family: 'Fira Sans', sans-serif
	font-weight: 500
	font-size: 1em
	color: $gray


@media (max-width: 1400px)

	h1
		font-size: 4em
		top: 35%


@media (max-width: 1130px)

	h1
		font-size: 3.75em
		top: 35%

	h2
		font-size: 1.4em



@media (max-width: 690px)

	h1
		font-size: 2.5em
		left: 10%
		top: 25%
