#logo
	position: relative
	left: 0
	top: 8%
	width: 500px
	max-height: 45px
	align-items: center
	img
		width: 70%
		max-height: 100%
		cursor: pointer

nav
	position: relative
	top: 0px
	margin: 0 auto
	padding: 0 auto
	width: 100vw
	background: white
	justify-content: center
	align-items: center
	z-index: 9999999999
	height: 60px
	.nav-container
		height: 100%
		max-width: 1600px
		position: relative
		display: flex
		margin: 0 auto
		padding: 0 10px
		justify-content: space-between
		align-items: center
		#burger
			width: 30px
			height: 30px
			position: relative
			cursor: pointer
			display: none
			transition: all 300ms ease
			#bars
				position: absolute
				top: 48%
				width: 100%
				height: 2px
				background: $gray
				transition: all 300ms ease
				transition: -50%
				left: 0
				border-radius: 2px
				

				&:before
					content: ""
					position: absolute
					width: 100%
					height: 100%
					top: -9px
					background: $gray
					pointer-events: none
					transition: all 300ms ease
					left: 0
					border-radius: 2px

				&:after
					content: ""
					position: absolute
					width: 100%
					height: 100%
					top: 9px
					background: $gray
					pointer-events: none
					transition: all 300ms ease
					left: 0
					border-radius: 2px
			&:hover
				#bars
					background: $orange

					&::before, &:after
						background: $orange

	.menu
		display: flex
		justify-content: flex-end
		align-items: center
		width: 80%
		height: 100%
		position: relative
		margin: 0 auto
		color: $gray
		background: white


		li
			width: auto
			margin: 0 30px
			text-align: center
			list-style-type: none
			cursor: pointer!important
			a, span
				cursor: pointer!important
				font-weight:	300
				font-family: 'Fira Sans', sans-serif
				text-transform: uppercase
				font-size: 1em
				color: $gray
				text-align: center
				text-decoration: none
				transition: all 300ms ease

			.nav
				border-bottom: 2px solid transparent
				&:hover
					border-bottom: 2px solid $orange
			.nav4
				border-bottom: 2px solid transparent
				&:hover
					border-bottom: 2px solid $orange


			.dropdownMenu
				position: absolute
				right: 240px
				margin-top: 35px
				top: -500px
				padding: 0
				height: 0
				overflow: hidden
				background: transparent
				z-index: 9999999999999999999999999999999999999999
				transition: all 100ms ease
				li
					width: auto
					margin: 0
					padding: 0
					a, span
						display: block
						padding: 10px 20px
						font-size: 1em
						border-bottom: 1px solid $light-gray
						&:hover
							background: $orange
							color: white
			&.drop
				padding: 10px 0
				@media (min-width: 750px)
					&:hover
						.dropdownMenu
							box-shadow: 0 3px 10px rgba(black, 0.3)
							display: block
							height: auto
							padding: 0px 0 0 0
							width: 260px
							top: 20px
							right: 240px
							background: white

							&:before
								content: '\25b2'
								position: relative
								width: 100%
								text-align: right

@media (max-width: 1130px)

	#logo
		width: 400px

	nav
		position: relative
		.menu
			display: flex
			li
				margin: 0 15px
				a, span
					font-size: 0.9em
				.dropdownMenu
					position: absolute
					li
						width: auto
						a, span
							font-size: 0.85em
				&.drop
					padding: 10px 0
					&:hover
						.dropdownMenu
							right: 90px





@media (max-width: 750px)

	#logo
		width: 250px

	nav
		height: 50px
		z-index: 99999999
		position: sticky
		.nav-container
			position: relative
			#burger
				display: block
				position: absolute
				right: 15px
				z-index: 99999999999999999999999999999
				&.x
					#bars
						background: transparent
						&:before
							background: $gray
							transform: rotate(-45deg)
							top: 0
						&:after
							background: $gray
							transform: rotate(45deg)
							top: 0
			.menu
				// background: white
				display: block
				position: absolute
				right: 0
				top: 0
				padding: 0
				height: auto
				width: 100%
				top: -400px
				transition: all 300ms ease
				li
					margin: 0 auto
					border-bottom: 1px solid #dcdcdc
					width: 85%

					&:last-child
						border-bottom: none
						margin-bottom: 10px

					a
						width: 100%
						height: 100%
						display: block
						text-align: left
						padding: 10px 0 20px 0
					span
						width: 100%
						height: 100%
						display: block
						text-align: left
						padding: 0 0 10px 0
					.nav:hover
						border-bottom: none
					.nav4:hover
						border-bottom: none

					&.drop
						.nav4
							position: relative
							cursor: pointer
							&:before
								content: '\25b6'
								position: absolute
								right: 30px
								top: 2px
								font-size: 0.825em
								transition: all 300ms ease
						.dropdownMenu
							position: relative
							right: 0
							margin-top: 0
							top: 0
							padding: 0
							height: 0
							overflow: hidden
							background: $light-gray
							z-index: 999999999999999
							transition: all 300ms ease
							width: 100%
							box-shadow: none
							&:before
								content: ''

							li
								width: auto
								margin: 0 auto
								padding: 0 auto
								a
									display: block
									// padding: 5px 20px
									text-transform: none
									border-bottom: 1px solid $light-gray
									font-size: 1em
									&:hover
										background: $orange
										color: white

						&.active
							transition: all 300ms ease
							.nav4
								position: relative
								cursor: pointer
								&:before
									content: '\25b6'
									position: absolute
									top: 2px
									font-size: 0.825em
									transform: rotate(-90deg)
							.dropdownMenu
								transition: all 300ms ease
								display: block
								height: 100%
								padding: 0
								top: 0
								right: 0
								background: $light-gray
								width: 100%
								box-shadow: none
								&:before
									content: ''
				&.open
					top: 45px
					background: white
