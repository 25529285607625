body
  background: white
  width: 100%
  height: 100%
  margin: 0 auto
  padding: 0 auto
  
section
  z-index: 0

.container
  width: 50%
  max-width: 800px
  margin: 0 auto
  padding: 0 auto
  z-index: 999
  margin-top: 50px
  margin-bottom: 80px

.container-tools
  width: 80%
  max-width: 1200px
  margin: 0 auto
  padding: 0 auto
  z-index: 999
  margin-top: 50px
  margin-bottom: 80px

.image
  height: 95vh
  width: 100%
  position: relative
  // z-index: -999

@media (max-width: 1400px)

  .image
    height: 70vh

  .container
    width: 60%



@media (max-width: 1130px)


  .container
    width: 80%
    margin-top: 100px
    margin-bottom: 100px


@media (max-width: 815px)

  .image
    height: 50vh


@media (max-width: 690px)

  .image
    height: 40vh

  .container
    width: 85%
    margin-top: 20px
    margin-bottom: 50px
