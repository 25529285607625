
.shape-top
	position: absolute
	top: -2px
	left: 0
	transform: rotate(-180deg)
	fill: white
	pointer-events: none


.shape-bottom
	position: absolute
	bottom: -2px
	right: 0
	fill: white
	pointer-events: none

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
	.shape-bottom, .shape-top
		display: none