#five
  position: relative

  .image-5
    background: url("../img/sarah-doody-603348-unsplash.jpg") center top 50%
    background-size: cover


  .news
    position: relative
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-top: 20px

    .news-1
      position: relative
      flex: 1 0 0
      margin: 0 30px

    .news-2
      position: relative
      flex: 1 0 0
      margin: 0 30px
      @media (max-width: 700px)
        margin: 0px

  .news-image
    width: 100%
    position: relative
    height: 250px

  .n1
    background: url("../img/n1.jpg")
    background-size: cover

  .n2
    background: url("../img/sync.jpg") center no-repeat
    background-size: contain


@media (max-width: 1130px)
  section#five

    .news
      position: relative
      margin-top: 100px



@media (max-width: 690px)
  section#five

    .news
      flex-direction: column

      .news-1
        width: 100%

      .news-2
        width: 100%
