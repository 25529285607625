#brillen
	position: relative

	.image-3
		background: url("../img/matthew-hamilton-166549-unsplash.jpg") center center
		background-size: cover

	.glasses
		position: relative
		width: 100%
		text-align: center


		.brillen-slider
			margin-top: 50px
			.brille
				img
					width: 70%
		.slick-next
			right: 20px


		.slick-prev
			left: 20px

		.sunbrillen-slider
			margin-top: 50px
			.brille
				img
					width: 70%


	.brands
		position: relative
		width: 100%
		margin-top: 50px
		margin: 0 auto
		display: flex
		flex-wrap: wrap
		justify-content: center
		align-items: center
		.logo-main
			flex: 0 0 30%
			padding: 20px
			cursor: pointer
			img
				max-width: 100%
				display: block
				height: auto
			@media (min-width: 640px)
				flex: 0 0 20%
				padding: 20px


@media (max-width: 1130px)
	section#brillen


		.brands
			width: 100%



@media (max-width: 690px)
	section#brillen
		position: relative

		.glasses
			position: relative
			width: 95%
			text-align: center

			.brillen-slider
				margin-top: 50px


		.slick-next
			right: 5px!important


		.slick-prev
			left: 5px!important

		.sunglasses
			margin-top: -100px

	
