.skyline__modal
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	background: rgba(black, 0.9)
	padding: 20px
	display: flex
	align-items: center
	overflow-y: auto

	&-content
		background: white
		width: 100%
		padding: 0
		max-width: 800px
		margin: 0 auto
		max-height: 100%

		.close
			display: inline-block
			margin-left: auto
			font-size: 16px
			padding: 10px
			cursor: pointer
		.skyline__lightbox-slider
			img
				width: 100%
				height: auto
				display: block
