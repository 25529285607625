#kontakt
  position: relative
  .image-2
    position: relative
    max-width: 100%
    display: block
    margin: 0 auto
    .shop-slider
      position: relative
      margin: 0px auto
      width: 100%
      text-align: center
      height: 95vh
      z-index: 0
      overflow: hidden
      #slide-1
        background: url("../img/shop-slideshow/20180406_113547.jpg") center center
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-2
        background: url("../img/shop-slideshow/161.jpg") center center
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-3
        background: url("../img/shop-slideshow/20190124_172843.jpg") center center
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-4
        background: url("../img/shop-slideshow/20180424_181254.jpg") center top 30%
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-5
        background: url("../img/shop-slideshow/20190124_093222.jpg") center top 30%
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-6
        background: url("../img/shop-slideshow/IMG-20180426-WA0005.jpg") center top 30%
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-7
        background: url("../img/shop-slideshow/20190124_154131.jpg") center top 30%
        background-size: cover
        position: absolute
        width: 100%
        height: 100%
      #slide-8
        background: url("../img/shop-slideshow/IMG-20180426-WA0006.jpg") center bottom 30%
        background-size: cover
        position: absolute
        width: 100%
        height: 100%


  @media (min-width: 691px) 
    .oeffnungeszeiten 
      padding-right: 50px
  .oeffnungeszeiten 
    display: flex
    align-items: flex-end
    flex-direction: column
    text-align: right
    font-family: 'Fira Sans',sans-serif
    color: #454545
    font-weight: 300
    td 
      font-size: 1.2rem


  .container
    width: 100%

  .container-map
    position: relative
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    .text-map
      position: relative
      width: 40%
      p
        text-align: right
        padding-right: 50px
        margin-top: 0
        font-size: 1.4rem

    .map
      position: relative
      width: 60%
      height: 500px
      justify-content: center
      z-index: 99
      #privacyProxyElement_1 
        max-width: 100%
      iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border: 0
        z-index: 9
@media (max-width: 1400px)
  section#kontakt
    position: relative
    .image-2
      position: relative
      .shop-slider
        height: 70vh
@media (max-width: 900px)
  section#kontakt
    .container-map
      width: 90%
      .text-map
        p 
          font-size: 1.2rem
@media (max-width: 815px)
  section#kontakt
    position: relative
    .image-2
      position: relative
      .shop-slider
        height: 50vh



@media (max-width: 690px)
  section#kontakt

    .image-2
      position: relative
      .shop-slider
        height: 40vh

    .container-map
      position: relative
      display: flex
      flex-wrap: wrap
      flex-direction: column

      .text-map
        width: 100%
        p
          padding-right: 0px


      .map
        margin-top: 50px
        width: 100%
        min-height: 350px
